import { useUserActions, useUserSelectors } from 'services/user';
import { useAllPageStatisticsByLanguage, SUMMARY_KEY } from 'services/page-statistics';
import { useCultureSelectors } from 'services/culture';
import {
  EXTRA_COLLECTION_ACHIEVEMENT_AMOUNTS,
  ExtraCollectionAchievement,
  ExtraCollectionAchievementLevel,
} from 'model/indexTS';
import { useMemo, useState } from 'react';
import { usePageSelectors } from 'services/page';
import { isAfter } from 'date-fns';
import { useReactAlert } from 'components/react-alert-provider';
import { useTranslation } from 'react-i18next';
import trackEventManager from 'utils/event-manager';

const TIME_TO_EXPIRE = 1000 * 60 * 60 * 24 * 30;

const getAchievementInfo = (
  achievement: ExtraCollectionAchievement | undefined,
  level: ExtraCollectionAchievementLevel,
  lootsSold: number,
): ExtraCollectionAchievement | undefined => {
  const requiredAmount = EXTRA_COLLECTION_ACHIEVEMENT_AMOUNTS[level];
  if (!achievement) {
    return {
      level,
      requiredAmount,
      expiresAt: new Date(Date.now() + TIME_TO_EXPIRE).toISOString(),
      startingAmount: lootsSold,
      progress: 0,
    };
  }

  if (!achievement.claimedAt) {
    const progress = lootsSold - achievement.startingAmount;
    return {
      ...achievement,
      isExpired: !achievement.completedAt && isAfter(Date.now(), achievement.expiresAt),
      progress,
    };
  }

  return achievement;
};

export const useExtraCollectionAchievements = () => {
  const {
    page: { isOwner },
  } = usePageSelectors();
  const { t } = useTranslation('dashboard');
  const { showSuccess } = useReactAlert();
  const [isLoading, setIsLoading] = useState(false);
  const { language } = useCultureSelectors();
  const statistics = useAllPageStatisticsByLanguage(language) ?? {};
  const { userPreferences } = useUserSelectors();
  const { extraCollectionAchievements = {} } = userPreferences;
  const { updateUserPreferences } = useUserActions();
  const statisticsSummary = statistics[SUMMARY_KEY];

  const extraCollections = useMemo(() => {
    if (!statisticsSummary || !isOwner) {
      return null;
    }

    const { purchasedLootsCount = 0, giftedLootsCount = 0 } = statisticsSummary;
    const lootsSold = purchasedLootsCount + giftedLootsCount;

    if (lootsSold < 500) {
      return null;
    }

    const levelKey = `level1`;
    const currentAchievement =
      extraCollectionAchievements[levelKey] &&
      getAchievementInfo(extraCollectionAchievements[levelKey], ExtraCollectionAchievementLevel.Level1, lootsSold);
    const temporalChallengeEventProps = {
      name: `Unlock extra collections ${ExtraCollectionAchievementLevel.Level1}`,
    };

    const startAchievement = async () => {
      setIsLoading(true);
      const response = await updateUserPreferences({
        extraCollectionAchievements: {
          ...extraCollectionAchievements,
          [levelKey]: getAchievementInfo(currentAchievement, ExtraCollectionAchievementLevel.Level1, lootsSold),
        },
      });

      if (!response.error) {
        trackEventManager.trackEvent('temporal_challenge_started', temporalChallengeEventProps, true);
      }

      setIsLoading(false);
    };

    const completeAchievement = async () => {
      if (!currentAchievement || isLoading) {
        return;
      }

      setIsLoading(true);
      const response = await updateUserPreferences({
        extraCollectionAchievements: {
          ...extraCollectionAchievements,
          [levelKey]: {
            ...currentAchievement,
            completedAt: new Date().toISOString(),
          },
        },
      });

      if (!response.error) {
        trackEventManager.trackEvent('temporal_challenge_completed', temporalChallengeEventProps, true);
      }

      setIsLoading(false);
    };

    const claimAchievement = async () => {
      if (!currentAchievement) {
        return;
      }
      setIsLoading(true);

      const response = await updateUserPreferences({
        extraCollectionAchievements: {
          ...extraCollectionAchievements,
          [levelKey]: {
            ...currentAchievement,
            claimedAt: new Date().toISOString(),
          },
        },
      });

      if (!response.error) {
        showSuccess(t('dashboard:challenges.claimed'));
        trackEventManager.trackEvent('temporal_challenge_claimed', temporalChallengeEventProps, true);
      }

      setIsLoading(false);
    };

    return {
      extraCollectionsUnlocked: currentAchievement?.claimedAt ? currentAchievement.level : 0,
      currentAchievement,
      startAchievement,
      completeAchievement,
      claimAchievement,
      isLoading,
    };
  }, [statisticsSummary, isOwner, extraCollectionAchievements, updateUserPreferences, isLoading, t, showSuccess]);

  return extraCollections;
};
