import { permissions, staffPermissions } from 'components/permissions';
import { PagesModules } from '@streamloots/streamloots-types';
import { MenuSections } from 'model/userTS';
import { PageModulesValues } from 'model/indexTS';

export interface MenuItemSpecialRenderConditions {
  setPublished?: boolean;
  hasSets?: boolean;
  hasPublishedSets?: boolean;
  showUntil?: string; // Date - To stop showing the link
  isOldUser?: string; // Date - To show the link only if the user created the account before this date.
  modulesRequired?: PageModulesValues | PageModulesValues[];
  activeAchievements?: boolean;
  hasPowers?: boolean;
}

export interface MenuItemRenderRequirements {
  specialConditions?: MenuItemSpecialRenderConditions;
  requiredPermissions?: string | Array<string>;
  enforcePermissions?: string | Array<string>;
}

export interface Link extends MenuItemRenderRequirements {
  i18nKey: string;
  icon: string;
  isNew?: boolean;
  onClickProperty?: string;
  relativePath?: boolean;
  toUrlKey?: string;
  dataId?: string; // To allow external tools such as userflow to create css selectors without caring about the text
}

export interface LinkSection extends MenuItemRenderRequirements {
  id: MenuSections;
  i18nKey: string;
  icon: string;
  links: Link[];
}

export const STAFF_LINKS: Link[] = [
  {
    i18nKey: 'Site coupons',
    icon: 'coupon',
    requiredPermissions: [
      permissions.ADMIN,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.SUPPORT,
    ],
    toUrlKey: 'ADMIN_COUPONS',
  },
  {
    i18nKey: 'Predefined cards',
    icon: 'card-configuration',
    requiredPermissions: [
      permissions.ADMIN,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.SUPPORT,
    ],
    toUrlKey: 'ADMIN_PREDEFINED_CARDS',
  },
  {
    i18nKey: 'common:menu.orderFrames',
    icon: 'order-alphabetical-ascending',
    requiredPermissions: [
      permissions.ADMIN,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.SUPPORT,
    ],
    toUrlKey: 'ADMIN_ORDER_FRAMES',
  },
  {
    i18nKey: 'Predefined frames',
    icon: 'predefined_card',
    requiredPermissions: [
      permissions.ADMIN,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.SUPPORT,
    ],
    toUrlKey: 'ADMIN_PREDEFINED_FRAMES',
  },
  {
    i18nKey: 'common:menu.adminCardCreator',
    icon: 'card',
    onClickProperty: 'onAdminCardClick',
    requiredPermissions: [
      permissions.ADMIN,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.SUPPORT,
    ],
  },
  {
    i18nKey: 'common:menu.notifications',
    icon: 'bell',
    requiredPermissions: [
      permissions.ADMIN,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.SUPPORT,
    ],
    toUrlKey: 'ADMIN_NOTIFICATIONS',
  },
  {
    i18nKey: 'Partnership Applications',
    icon: 'redemptions',
    requiredPermissions: [permissions.ADMIN, permissions.PARTNERSHIP_MANAGER],
    toUrlKey: 'ADMIN_PARTNERSHIP_APPLICATIONS',
  },
  {
    i18nKey: 'Tags',
    icon: 'tag',
    requiredPermissions: [permissions.ADMIN],
    toUrlKey: 'ADMIN_TAGS',
  },
  {
    i18nKey: 'common:menu.reactionsCreator',
    icon: 'star',
    requiredPermissions: [...staffPermissions],
    toUrlKey: 'ADMIN_REACTIONS',
  },
  {
    i18nKey: 'common:menu.emotesCreator',
    icon: 'party-popper',
    requiredPermissions: [...staffPermissions],
    toUrlKey: 'ADMIN_EMOTES',
  },
  {
    i18nKey: 'Payouts',
    icon: 'sack_money',
    requiredPermissions: [permissions.ADMIN],
    toUrlKey: 'ADMIN_PAYOUTS',
  },
];

export const TOP_STREAMERS_LEADERBOARD = {
  i18nKey: 'common:menu.monthlyLeaderboard',
  icon: 'cup',
  toUrlKey: 'TOP_STREAMERS',
  dataId: 'monthlyLeaderboard',
};

export const VIEWER_LINKS: Link[] = [
  {
    i18nKey: 'common:menu.browse',
    icon: 'dashboard',
    dataId: 'streamersList',
    toUrlKey: 'STREAMERS',
  },
  {
    i18nKey: 'common:menu.myStreamers',
    icon: 'person',
    dataId: 'myStreamers',
    toUrlKey: 'USER_COLLECTIONS',
  },
  {
    i18nKey: 'common:menu.extensions',
    icon: 'stream-deck',
    dataId: 'extensions',
    toUrlKey: 'EXTENSIONS',
    //isNew: true,
  },
  {
    i18nKey: 'common:menu.powers',
    icon: 'flash',
    toUrlKey: 'POWERS',
    dataId: 'powers',
    isNew: true,
    requiredPermissions: [permissions.USER_AUTHENTICATED],
    specialConditions: {
      hasPowers: true,
    },
  },
  {
    i18nKey: 'common:menu.achievements',
    icon: 'achievements',
    toUrlKey: 'ACHIEVEMENTS',
    dataId: 'achievements',
    isNew: true,
    requiredPermissions: [permissions.USER_AUTHENTICATED],
    specialConditions: {
      activeAchievements: true,
    },
  },
];

export const CREATOR_LINKS = {
  PUBLIC: {
    i18nKey: 'common:menu.viewPublicPage',
    icon: 'viewdetail',
    toUrlKey: 'USER_PAGE',
    dataId: 'publicStreamerPage',
    requiredPermissions: [permissions.USER_AUTHENTICATED],
  },
  DASHBOARD: {
    i18nKey: 'common:menu.dashboard',
    icon: 'dashboard',
    toUrlKey: 'DASHBOARD',
    dataId: 'dashboard',
    requiredPermissions: [
      permissions.SUPPORT,
      permissions.PAGE_OWNER,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.STREAMER_SUCCESS_MANAGER,
      permissions.ADMIN,
    ],
  },
  AUCTIONS: {
    i18nKey: 'common:menu.auctions',
    toUrlKey: 'AUCTIONS',
    icon: 'auction',
    dataId: 'auctions',
    relativePath: true,
    requiredPermissions: [permissions.AUCTION_EDIT],
  },
  SUBSCRIPTIONS: {
    i18nKey: 'common:menu.subscription',
    toUrlKey: 'PAGE_SUBSCRIPTIONS',
    icon: 'star',
    dataId: 'subscription',
    relativePath: true,
    requiredPermissions: [
      permissions.PAGE_OWNER,
      permissions.ADMIN,
      permissions.SUPPORT,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.STREAMER_SUCCESS_MANAGER,
    ],
  },
  MARATHONS: {
    i18nKey: 'common:menu.marathons',
    toUrlKey: 'MARATHON',
    icon: 'marathon',
    dataId: 'marathon',
    relativePath: true,
    requiredPermissions: [
      permissions.PAGE_OWNER,
      permissions.MARATHON_EDIT,
      permissions.ADMIN,
      permissions.SUPPORT,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.STREAMER_SUCCESS_MANAGER,
    ],
  },
  DONATIONS: {
    i18nKey: 'common:menu.donations',
    toUrlKey: 'PAGE_DONATIONS',
    icon: 'coins',
    dataId: 'donations',
    isNew: true,
    relativePath: true,
    requiredPermissions: [
      permissions.PAGE_OWNER,
      permissions.ADMIN,
      permissions.SUPPORT,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.STREAMER_SUCCESS_MANAGER,
    ],
  },
  STAFF: {
    i18nKey: 'common:menu.administration',
    icon: 'admin_shield',
    toUrlKey: 'ADMINISTRATION',
    requiredPermissions: [
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.ADMIN,
      permissions.STREAMER_SUCCESS_MANAGER,
      permissions.SUPPORT,
    ],
  },
  REVENUE: {
    i18nKey: 'common:menu.revenue',
    icon: 'sack_money',
    requiredPermissions: [permissions.ORDERS_READ, permissions.ORDERS_READ_GIFTS],
    relativePath: true,
    toUrlKey: 'REVENUE',
    dataId: 'revenue',
  },
  ACTIVITY_FEED: {
    i18nKey: 'common:menu.activityFeed',
    icon: 'redemptions',
    requiredPermissions: permissions.REDEMPTIONS_READ,
    specialConditions: {
      modulesRequired: [PagesModules.Cards, PagesModules.Subscriptions],
    },
    toUrlKey: 'ACTIVITY_FEED',
    dataId: 'activityFeed',
  },
  ENABLE_CARDS: {
    i18nKey: 'common:menu.cards',
    toUrlKey: 'ENABLE_CARDS',
    icon: 'cards',
    dataId: 'subscriptions',
    relativePath: true,
    requiredPermissions: [
      permissions.PAGE_OWNER,
      permissions.ADMIN,
      permissions.SUPPORT,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.STREAMER_SUCCESS_MANAGER,
    ],
  },
  ENABLE_SUBSCRIPTIONS: {
    i18nKey: 'common:menu.subscription',
    toUrlKey: 'ENABLE_SUBSCRIPTIONS',
    icon: 'star',
    dataId: 'subscriptions',
    relativePath: true,
    requiredPermissions: [
      permissions.PAGE_OWNER,
      permissions.ADMIN,
      permissions.SUPPORT,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.STREAMER_SUCCESS_MANAGER,
    ],
  },
  ENABLE_AUCTIONS: {
    i18nKey: 'common:menu.auctions',
    toUrlKey: 'ENABLE_AUCTIONS',
    icon: 'auction',
    dataId: 'auctions',
    requiredPermissions: [
      permissions.PAGE_OWNER,
      permissions.ADMIN,
      permissions.SUPPORT,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.STREAMER_SUCCESS_MANAGER,
    ],
  },
  ENABLE_MARATHONS: {
    i18nKey: 'common:menu.marathons',
    toUrlKey: 'ENABLE_MARATHONS',
    icon: 'marathon',
    dataId: 'marathons',
    requiredPermissions: [
      permissions.PAGE_OWNER,
      permissions.ADMIN,
      permissions.SUPPORT,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.STREAMER_SUCCESS_MANAGER,
    ],
  },
  ENABLE_DONATIONS: {
    i18nKey: 'common:menu.donations',
    toUrlKey: 'ENABLE_DONATIONS',
    icon: 'coins',
    dataId: 'donations',
    isNew: true,
    requiredPermissions: [
      permissions.PAGE_OWNER,
      permissions.ADMIN,
      permissions.SUPPORT,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.STREAMER_SUCCESS_MANAGER,
    ],
  },
  MY_VIEWERS_COMMUNITY: {
    i18nKey: 'common:menu.myCommunity',
    icon: 'base_community-users',
    requiredPermissions: [
      permissions.PAGE_OWNER,
      permissions.PERMISSIONS_GRANT,
      permissions.BAN_FROM_PAGE_EDIT,
      permissions.ADMIN,
      permissions.SUPPORT,
      permissions.ACCOUNT_MANAGER,
      permissions.PARTNERSHIP_MANAGER,
      permissions.STREAMER_SUCCESS_MANAGER,
    ],
    toUrlKey: 'MY_VIEWERS_COMMUNITY',
    dataId: 'myCommunity',
  },
};

export const STREAMER_SECTIONS: Record<string, LinkSection> = {
  collections: {
    id: MenuSections.Collections,
    i18nKey: 'common:menu.cards',
    icon: 'cards',
    requiredPermissions: [
      permissions.SETS_EDIT,
      permissions.SET_CARDS_READ,
      permissions.SET_RARITIES_EDIT,
      permissions.CRAFTING,
      permissions.SET_LOOTS_EDIT,
      permissions.CHEST_GIFT,
      permissions.COUPONS_READ,
      permissions.RANKINGS_PURCHASES_READ,
      permissions.RANKINGS_REDEMPTIONS_READ,
    ],
    specialConditions: {
      modulesRequired: PagesModules.Cards,
    },
    links: [
      {
        i18nKey: 'common:menu.myCollections',
        icon: 'settings',
        toUrlKey: 'MANAGE_COLLECTIONS',
        dataId: 'manageCollections',
        requiredPermissions: permissions.SETS_EDIT,
        specialConditions: {
          modulesRequired: PagesModules.Cards,
        },
      },
      {
        i18nKey: 'common:menu.manageCollection',
        icon: 'card-configuration',
        toUrlKey: 'MANAGE_COLLECTION',
        dataId: 'manageCollection',
        relativePath: true,
        specialConditions: {
          hasSets: true,
          modulesRequired: PagesModules.Cards,
        },
        requiredPermissions: [
          permissions.SET_LOOTS_EDIT,
          permissions.SET_CARDS_EDIT,
          permissions.CRAFTING,
          permissions.SET_RARITIES_EDIT,
        ],
      },
      {
        i18nKey: 'common:menu.gift',
        icon: 'gift',
        onClickProperty: 'onGiftClick',
        dataId: 'giftPacks',
        requiredPermissions: permissions.CHEST_GIFT,
        specialConditions: {
          setPublished: true,
          modulesRequired: PagesModules.Cards,
        },
      },
      {
        i18nKey: 'common:menu.coupons',
        icon: 'coupon',
        toUrlKey: 'PAGE_COUPONS',
        dataId: 'pageCoupons',
        requiredPermissions: permissions.COUPONS_READ,
        specialConditions: {
          hasPublishedSets: true,
          modulesRequired: PagesModules.Cards,
        },
      },
      {
        i18nKey: 'common:menu.rankings',
        icon: 'cup',
        enforcePermissions: [permissions.RANKINGS_PURCHASES_READ, permissions.RANKINGS_REDEMPTIONS_READ],
        requiredPermissions: [
          permissions.RANKINGS_PURCHASES_READ,
          permissions.RANKINGS_REDEMPTIONS_READ,
          permissions.RANKINGS_PUBLIC_REDEMPTIONS,
          permissions.RANKINGS_PUBLIC_PURCHASES,
        ],
        toUrlKey: 'RANKINGS',
        dataId: 'rankings',
        specialConditions: {
          hasPublishedSets: true,
          modulesRequired: PagesModules.Cards,
        },
      },
      {
        i18nKey: 'common:menu.originals',
        icon: 'cards',
        toUrlKey: 'ORIGINALS',
        dataId: 'originals',
        specialConditions: {
          modulesRequired: PagesModules.Cards,
        },
        requiredPermissions: [permissions.SET_CARDS_EDIT],
      },
    ],
  },
  administration: {
    id: MenuSections.Configuration,
    i18nKey: 'common:menu.configuration',
    icon: 'settings',
    requiredPermissions: [
      permissions.ALERT_PROVIDERS_EDIT,
      permissions.GOALS_EDIT,
      permissions.ADMIN,
      permissions.AUCTION_EDIT,
      permissions.PAGE_EDIT,
    ],
    links: [
      {
        i18nKey: 'common:menu.alertsAndGoals',
        toUrlKey: 'WIDGETS',
        dataId: 'widgets',
        icon: 'bell',
        relativePath: true,
        requiredPermissions: [permissions.ALERT_PROVIDERS_EDIT, permissions.GOALS_EDIT],
        specialConditions: {
          modulesRequired: [PagesModules.Cards, PagesModules.Auctions, PagesModules.Subscriptions],
        },
      },
      {
        i18nKey: 'common:menu.settings',
        icon: 'settings',
        requiredPermissions: [permissions.PAGE_EDIT, permissions.COOLDOWN_EDIT, permissions.PAGE_OWNER],
        toUrlKey: 'CONFIGURE_PAGE',
        dataId: 'settings',
      },
    ],
  },
};
