export interface ExtraCollectionAchievement {
  completedAt?: string;
  level: number;
  requiredAmount: number;
  startingAmount: number;
  progress: number;
  expiresAt: string;
  isExpired?: boolean;
  claimedAt?: string;
}

export enum ExtraCollectionAchievementLevel {
  Level1 = 1,
  Level2 = 2,
  Level3 = 3,
}

export const EXTRA_COLLECTION_ACHIEVEMENT_AMOUNTS = {
  [ExtraCollectionAchievementLevel.Level1]: 100,
  [ExtraCollectionAchievementLevel.Level2]: 180,
};
