import { Trans, useTranslation } from 'utils/translation';
import { urls } from 'services/routing';
import { MiniBanner } from 'components/mini-banner';
import Button from 'ui-library/button';
import { useEffect } from 'react';
import { ProgressBar } from 'ui-library/progress-bar';
import { CompletenessLabel } from 'components/achievement/CompletenessLabel';
import { Link } from 'ui-library/link';
import { useExtraCollectionAchievements } from './useExtraCollectionAchievements';

interface UnlockCollectionAchievementDashboardProps {
  showTitle?: boolean;
}

export const UnlockCollectionAchievementDashboard = ({
  showTitle,
}: UnlockCollectionAchievementDashboardProps): JSX.Element | null => {
  const { t, i18n } = useTranslation('dashboard');
  const achievementInfo = useExtraCollectionAchievements();

  useEffect(() => {
    if (!achievementInfo?.currentAchievement || achievementInfo.isLoading) {
      return;
    }

    const { progress, isExpired, requiredAmount, completedAt } = achievementInfo.currentAchievement;
    if (completedAt || isExpired || progress < requiredAmount) {
      return;
    }

    achievementInfo.completeAchievement();
  }, [achievementInfo]);

  if (!achievementInfo || achievementInfo.currentAchievement?.claimedAt) {
    return null;
  }

  const { isLoading, currentAchievement, startAchievement, claimAchievement } = achievementInfo;

  return (
    <>
      {showTitle && <h2 className="mb-8 align-center">{t('dashboard:challenges.title')}</h2>}
      <div className="flex justify-center mb-12">
        <MiniBanner
          icon="cards"
          badgeColor="orange"
          color="orange"
          size="md"
          text={
            <>
              {currentAchievement ? (
                <Trans i18nKey="dashboard:challenges.extraCollection.descriptionStarted">
                  {`Vende 100 cofres en menos de 30 días para conseguir un hueco para publicar  `}
                  <Link to={urls().MANAGE_COLLECTIONS} className="font-bold">
                    {'una colección'}
                  </Link>
                  {' extra'}
                </Trans>
              ) : (
                <>
                  <strong className="color-orange">{t('dashboard:challenges.extraCollection.moreCollections')} </strong>
                  {' · '}
                  {t('dashboard:challenges.extraCollection.description')}
                </>
              )}
            </>
          }
          to={urls().PAGE_COUPONS}
          tip={t('dashboard:challenges.badge')}
          footer={
            <>
              {currentAchievement && !currentAchievement.isExpired && (
                <div>
                  <ProgressBar
                    max={currentAchievement.requiredAmount}
                    value={
                      currentAchievement.completedAt ? currentAchievement.requiredAmount : currentAchievement.progress
                    }
                    showLegend
                    size="xl"
                    variation="partner"
                    rounded
                  />
                  <div className="align-right mt-2">
                    <CompletenessLabel
                      isCompleted={!!currentAchievement.completedAt}
                      expiresAt={currentAchievement.expiresAt}
                      completedAt={currentAchievement.completedAt}
                      t={t}
                      language={i18n.language}
                    />
                  </div>
                </div>
              )}
              {currentAchievement?.isExpired && (
                <>
                  <p className="align-center color-error">{t('dashboard:challenges.expired')}</p>
                  <div className="flex justify-center">
                    <Button
                      variant="filled"
                      color="info"
                      loading={isLoading}
                      label={t('dashboard:challenges.startAgain')}
                      onClick={() => {
                        startAchievement();
                      }}
                    />
                  </div>
                </>
              )}
              {currentAchievement?.completedAt && (
                <>
                  <div className="flex justify-center">
                    <Button
                      variant="filled"
                      primary
                      loading={isLoading}
                      label={t('dashboard:challenges.claim')}
                      onClick={() => {
                        claimAchievement();
                      }}
                    />
                  </div>
                </>
              )}
              {!currentAchievement && (
                <div className="flex justify-center">
                  <Button
                    variant="filled"
                    color="info"
                    loading={isLoading}
                    label={t('dashboard:challenges.button')}
                    onClick={() => {
                      startAchievement();
                    }}
                  />
                </div>
              )}
            </>
          }
        />
      </div>
    </>
  );
};
