import { memo } from 'react';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons/Icons';
import { OptionalTooltip } from 'ui-library/tooltip';
import theme from './progress-bar.scss';

const classNames = ClassNames(theme);

export interface ProgressBarTick {
  value: number;
  failed?: boolean;
  tooltip?: string;
  current?: boolean;
}

export interface ProgressBarProps {
  className?: string;
  big?: boolean;
  size?: 'xl';
  value: number;
  max: number;
  innerText?: string;
  variation?: 'affiliate' | 'partner';
  rounded?: boolean;
  ticks?: ProgressBarTick[];
  showLegend?: boolean;
  dataTestId?: string;
}

const ProgressBarInternal = ({
  className = '',
  variation,
  innerText,
  big,
  rounded,
  max = 100,
  value,
  ticks,
  showLegend,
  size,
  dataTestId,
}: ProgressBarProps): JSX.Element => {
  const getPercentage = (valuePercentage: number, maxPercentage: number) => {
    return (valuePercentage / maxPercentage) * 100 || 0;
  };
  const percentage = getPercentage(value, max);
  const width = 1 / max;
  const progressLabel = `${value}/${max} `;
  const progressBarClassNames = classNames({
    'progressbar': true,
    'progressbar--big': big,
    [`progressbar--${size}`]: size,
    [`progressbar--${variation}`]: variation,
    'progressbar--rounded': rounded,
    'progressbar--completed': value >= max,
    [className]: className,
  });

  return (
    <div className={progressBarClassNames} data-testid={dataTestId}>
      <div className={classNames('bar')} style={{ width: `${percentage}%` }} />
      {ticks?.map(tick => {
        const tickCompleted = tick.value <= value;
        let label = tick.failed ? 'failed' : undefined;

        if (!label && tickCompleted) {
          label = 'completed';
        }

        return (
          <OptionalTooltip key={tick.value} tooltip={tick.tooltip}>
            <div
              className={classNames({
                'bar__tick': true,
                'bar__tick--completed': tick.value <= value,
                'bar__tick--current': tick.current,
              })}
              style={{ left: `${width * 100 * tick.value}%` }}
              aria-label={label}
            >
              {!tick.failed && tick.value <= value && <Icon id="check" />}
              {tick.failed && <Icon id="close" className="color-error" />}
            </div>
          </OptionalTooltip>
        );
      })}
      {innerText || showLegend ? (
        <span className={classNames('bar__innerstep')}>
          {progressLabel}
          {innerText && <span className={classNames('bar__innertext')}>{innerText}</span>}
        </span>
      ) : (
        <span className="visuallyhidden">{progressLabel}</span>
      )}
    </div>
  );
};

export const ProgressBar = memo<ProgressBarProps>(ProgressBarInternal);
