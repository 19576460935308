import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Languages } from '@streamloots/streamloots-types';
import { pageStatisticsActions } from './actions';
import { pageStatisticsSelectors } from './selectors';
import {
  AllStatisticsOutput,
  FetchMonthPageStatistics,
  FetchPageStatistics,
  FetchPageStatisticsByComponentKey,
} from './types';

interface UsePageStatisticsActions {
  fetchCurrentMonthStatistics: FetchMonthPageStatistics;
  fetchPageStatistics: FetchPageStatistics;
  fetchPageStatisticsByComponentKey: FetchPageStatisticsByComponentKey;
}

export const usePageStatisticsActions = (): UsePageStatisticsActions => {
  const dispatch = useDispatch();
  return useMemo(
    () => ({
      fetchCurrentMonthStatistics: (pageId, date) => dispatch(pageStatisticsActions.fetchPageStatistics(pageId, date)),
      fetchPageStatistics: (pageId, fromDate) => dispatch(pageStatisticsActions.fetchPageStatistics(pageId, fromDate)),
      fetchPageStatisticsByComponentKey: params =>
        dispatch(pageStatisticsActions.fetchPageStatisticsByComponentKey(params)),
    }),
    [dispatch],
  );
};

export const useAllPageStatisticsByLanguage = (language: Languages): AllStatisticsOutput => {
  return useSelector(state => pageStatisticsSelectors.allStatistics(state, language));
};
