import loadable from 'components/dynamic-import';
import Render from 'components/render';
import { FullLayout } from 'scenes/layout';

const Browser = loadable(() => import('./Browser'));

export const BrowserExtensionPage = (): JSX.Element => {
  return (
    <FullLayout>
      <Render.Client>
        <Browser />
      </Render.Client>
    </FullLayout>
  );
};
