import _clean from 'lodash-clean';
import { Dispatch } from '@reduxjs/toolkit';
import type { UserPreferences } from 'model/userTS';
import actionCreator from 'helpers/actionCreator';
import endpoints from 'services/endpoints';
import { AuthProviders } from '@streamloots/streamloots-types';
import type {
  FetchCurrentUserActionSuccess,
  FetchCurrentUserActionError,
  Score,
  UpdateSettingsParams,
  UpdateUserSocialMediaParams,
  UserRootState,
} from './types';
import normalizer from './normalizer';
import { UserActionTypes } from './actionTypes';
import { errorMapping } from './errorMapping';
import { UpdateUserEmailParams, UpdateUserProfileParams } from './types';
import { userSelectors } from './selectors';

const currentUserInfoReceived = ({ data }): FetchCurrentUserActionSuccess => {
  return actionCreator.createAction(UserActionTypes.RECEIVED, normalizer.userResponse(data));
};

const currentUserInfoNotReceived = ({ errorData }): FetchCurrentUserActionError =>
  actionCreator.createErrorAction(UserActionTypes.RECEIVED, errorData);

const fetchCurrentUser = () =>
  actionCreator.createAsyncAction({
    method: 'get',
    endpoint: endpoints.USER.GET_CURRENT_USER_INFO,
    errorBinding: currentUserInfoNotReceived,
    loadingAction: UserActionTypes.REQUEST,
    successBinding: currentUserInfoReceived,
    includeExperiments: true,
  });

const userSettingsUpdated = ({ data }) =>
  actionCreator.createAction(UserActionTypes.UPDATED, normalizer.userResponse(data));

const userProfileUpdated = ({ data }) =>
  actionCreator.createAction(UserActionTypes.UPDATED_PROFILE, normalizer.userResponse(data));

const userSettingsNotUpdated = ({ errorData }) => actionCreator.createErrorAction(UserActionTypes.UPDATED, errorData);

const userProfileNotUpdated = ({ errorData }) =>
  actionCreator.createErrorAction(UserActionTypes.UPDATED_PROFILE, errorData, errorMapping);

const updateUserSettings = (params: UpdateSettingsParams) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.USER.UPDATE_USER,
    errorBinding: userSettingsNotUpdated,
    loadingAction: UserActionTypes.UPDATING,
    method: 'patch',
    parameters: params,
    successBinding: userSettingsUpdated,
  });

const updateUserProfile = (params: UpdateUserProfileParams) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.USER.UPDATE_USER_PROFILE,
    errorBinding: userProfileNotUpdated,
    loadingAction: UserActionTypes.UPDATING_PROFILE,
    method: 'put',
    parameters: params,
    successBinding: userProfileUpdated,
  });

const netPromoterScoreUpdated = () => actionCreator.createAction(UserActionTypes.NET_PROMOTER_SCORE_UPDATED);

const netPromoterScoreNotUpdated = ({ errorData }) =>
  actionCreator.createErrorAction(UserActionTypes.NET_PROMOTER_SCORE_UPDATED, errorData);

const updateNetPromoterUserScore = (parameters: Score) => {
  const { comment, score, netPromoterScoreId } = parameters;
  return actionCreator.createAsyncAction({
    endpoint: endpoints.USER.NET_PROMOTER_SCORE,
    loadingAction: UserActionTypes.UPDATING_NET_PROMOTER_SCORE,
    method: 'put',
    parameters: _clean({ comment: comment || undefined, score }),
    successBinding: netPromoterScoreUpdated,
    urlParameters: { netPromoterScoreId },
    errorBinding: netPromoterScoreNotUpdated,
  });
};

const userPreferencesUpdated = ({ data }) => {
  return actionCreator.createAction(UserActionTypes.USER_PREFERENCES_UPDATED, normalizer.userResponse(data));
};

const userPreferencesNotUpdated = ({ errorData }) => {
  return actionCreator.createErrorAction(UserActionTypes.USER_PREFERENCES_UPDATED, errorData);
};

const userEmailUpdated = ({ data }) => {
  return actionCreator.createAction(UserActionTypes.UPDATED_USER_EMAIL, data);
};

const userEmailNotUpdated = ({ errorData }) => {
  return actionCreator.createErrorAction(UserActionTypes.UPDATED_USER_EMAIL, errorData, errorMapping);
};

const updateUserPreferences: (preferences: UserPreferences) => any = (preferences: UserPreferences) => async (
  dispatch: Dispatch,
  getState: any,
) => {
  const lastUserPreferences = userSelectors.userPreferences(getState());
  const preferencesToUpdate = { ...lastUserPreferences, ...preferences };
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    powersBetaForm,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    predefinedCollectionNotifications,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    christmasAlertLastSeenAt,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    feedbackForms,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showOldActivityFeed,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    nextTimeToShowCampaignBanner,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    claimedHalloween100Revenue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    claimedSanValentin2022100Revenue,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    nextModulesScoring,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    newPackSellersPromotion,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    completeTaskTest,
    ...rest
  } = preferencesToUpdate;

  return dispatch(
    actionCreator.createAsyncAction({
      endpoint: endpoints.USER.UPDATE_PREFERENCES,
      loadingAction: UserActionTypes.UPDATING_USER_PREFERENCES,
      method: 'put',
      parameters: rest,
      successBinding: userPreferencesUpdated,
      errorBinding: userPreferencesNotUpdated,
    }),
  );
};

const updateUserEmail = (parameters: UpdateUserEmailParams) => {
  return actionCreator.createAsyncAction({
    endpoint: endpoints.USER.UPDATE_EMAIL,
    loadingAction: UserActionTypes.UPDATING_USER_EMAIL,
    method: 'post',
    parameters,
    successBinding: userEmailUpdated,
    errorBinding: userEmailNotUpdated,
  });
};

const userSocialMediaNotUpdated = ({ errorData }) => {
  return actionCreator.createErrorAction(UserActionTypes.UPDATED_SOCIAL_MEDIA, errorData, errorMapping);
};

const updateUserSocialMedia = (parameters: UpdateUserSocialMediaParams) => {
  return actionCreator.createAsyncAction({
    endpoint: endpoints.USER.PATCH,
    loadingAction: UserActionTypes.UPDATING_SOCIAL_MEDIA,
    method: 'patch',
    parameters,
    successBinding: currentUserInfoReceived,
    errorBinding: userSocialMediaNotUpdated,
  });
};

const userProfileFetched = ({ data }) =>
  actionCreator.createAction(UserActionTypes.CURRENT_USER_PROFILE_BY_PROVIDER_RECEIVED, data);

const userProfileNotFetched = ({ errorData }) =>
  actionCreator.createErrorAction(UserActionTypes.CURRENT_USER_PROFILE_BY_PROVIDER_RECEIVED, errorData, errorMapping);

const fetchUserProfileByProvider = (provider: AuthProviders) =>
  actionCreator.createAsyncAction({
    endpoint: endpoints.USER.GET_PROFILE_BY_PROVIDER,
    loadingAction: UserActionTypes.RETRIEVING_CURRENT_USER_PROFILE_BY_PROVIDER,
    method: 'get',
    urlParameters: { provider },
    successBinding: userProfileFetched,
    errorBinding: userProfileNotFetched,
  });

const verifyEmailReceived = ({ data }) => {
  return actionCreator.createAction(UserActionTypes.VERIFY_RECEIVED, data);
};

const verifyEmailNotReceived = ({ errorData }) => {
  return actionCreator.createErrorAction(UserActionTypes.VERIFY_RECEIVED, errorData);
};

const verifyEmail = (verificationId: string) => {
  return actionCreator.createAsyncAction({
    endpoint: endpoints.USER.VERIFY_EMAIL,
    errorBinding: verifyEmailNotReceived,
    urlParameters: {
      verificationId,
    },
    method: 'post',
    loadingAction: UserActionTypes.VERIFYING,
    successBinding: verifyEmailReceived,
  });
};

const deleteUserSuccess = () => {
  return actionCreator.createAction(UserActionTypes.DELETE_SUCCESS);
};

const deleteUserError = ({ errorData }) => {
  return actionCreator.createErrorAction(UserActionTypes.DELETE_ERROR, errorData);
};

const deleteUser = (userId: string) => {
  return actionCreator.createAsyncAction({
    endpoint: endpoints.USER.DELETE,
    errorBinding: deleteUserError,
    urlParameters: {
      userId,
    },
    method: 'delete',
    loadingAction: UserActionTypes.DELETE_REQUEST,
    successBinding: deleteUserSuccess,
  });
};

export const userActions = {
  fetchCurrentUser,
  updateUserSocialMedia,
  updateUserSettings,
  updateUserProfile,
  updateNetPromoterUserScore,
  fetchUserProfileByProvider,
  updateUserPreferences,
  updateUserEmail,
  verifyEmail,
  deleteUser,
};
