import { ReactNode, useEffect } from 'react';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons/Icons';
import { Badge, BadgeColorsType } from 'ui-library/badge';
import Button from 'ui-library/button';
import { Link } from 'ui-library/link';
import theme from './mini-banner.scss';

const classNames = ClassNames(theme);

export interface MiniBannerProps {
  text: string | React.ReactNode;
  tip: string;
  color?: 'legendary' | 'common' | 'info' | 'orange';
  badgeColor?: BadgeColorsType;
  icon: string;
  onClick?: () => void;
  ctaLabel?: string;
  handleResolve?: () => void;
  resolveCtaLabel?: string;
  to?: string;
  viewedEventFn?: () => void;
  classNameCta?: string;
  classNameResolveCta?: string;
  footer?: ReactNode;
  size?: 'md';
}

export const MiniBanner = ({
  text,
  tip,
  icon,
  onClick,
  handleResolve,
  color = 'info',
  badgeColor,
  ctaLabel,
  resolveCtaLabel,
  to,
  viewedEventFn,
  classNameCta = '',
  classNameResolveCta = '',
  footer,
  size,
}: MiniBannerProps): JSX.Element => {
  useEffect(() => {
    if (viewedEventFn != null) {
      viewedEventFn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames({
        'mini-banner': true,
        [`mini-banner--${size}`]: size,
        [`mini-banner-color--${color}`]: true,
      })}
    >
      <div className={classNames('mini-banner__top')}>
        <Badge color={badgeColor || color} className={classNames('mini-banner__top__chip')} label={tip} />
        <Icon id={icon} className={classNames('mini-banner__top__icon')} />
      </div>
      <div className={classNames('mini-banner__text')}>{text}</div>
      {footer || (
        <div className={classNames('mini-banner__buttons')}>
          {to === undefined && (
            <Button
              className={classNames({ 'mini-banner__button': true, [classNameCta]: classNameCta })}
              label={ctaLabel}
              onClick={onClick}
            />
          )}
          {to !== undefined && (
            <Button
              component={Link}
              to={to}
              className={classNames({ 'mini-banner__button': true, [classNameCta]: classNameCta })}
              label={ctaLabel}
              onClick={onClick}
            />
          )}
          {handleResolve && resolveCtaLabel && (
            <Button label={resolveCtaLabel} onClick={handleResolve} weird className={classNames(classNameResolveCta)} />
          )}
        </div>
      )}
    </div>
  );
};
