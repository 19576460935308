import Countdown from 'react-countdown-now';
import { TranslateInterface } from 'utils/translation';
import { isAfter, isBefore } from 'date-fns';
import { useState } from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './achievements.scss';

const classNames = ClassNames(theme);

interface Props extends TranslateInterface {
  isCompleted: boolean;
  completedAt?: string;
  expiresAt?: string;
  startsAt?: string;
  language: string;
}

export function achievedDate(language: string, completedAt?: string): string {
  const completedDate = completedAt ? new Date(completedAt) : new Date();
  const localeDates = { en: 'en-US', es: 'es-ES' };

  return completedDate.toLocaleDateString(localeDates[language], {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
}

export const CompletenessLabel = (props: Props): JSX.Element | null => {
  const { t, isCompleted, completedAt, startsAt, expiresAt, language } = props;
  const classes = classNames({
    'expires': true,
    'is-completed': isCompleted,
  });
  const [isExpired, setIsExpired] = useState(() => !!expiresAt && isAfter(Date.now(), expiresAt));

  if (isCompleted) {
    return (
      <p className={classes}>
        {t('achievements:achievedWithDate', {
          completedAt: achievedDate(language, completedAt),
        })}
      </p>
    );
  }

  if (!expiresAt) {
    return null;
  }

  if (isExpired) {
    return <p className={classes}>{t('achievements:expired')}</p>;
  }

  if (startsAt && isBefore(Date.now(), startsAt)) {
    return (
      <p className="color-legendary">
        {`${t('achievements:starts')} `}
        <Countdown
          date={startsAt}
          renderer={({ days, hours, minutes, seconds }) => `${days}d ${hours}h ${minutes}m ${seconds}s`}
        />
      </p>
    );
  }

  return (
    <p className={classes}>
      {`${t('achievements:expires')} `}
      <Countdown
        date={expiresAt}
        renderer={({ days, hours, minutes, seconds }) => `${days}d ${hours}h ${minutes}m ${seconds}s`}
        onComplete={() => {
          setIsExpired(true);
        }}
      />
    </p>
  );
};
