import { BasicUserData } from 'model/userTS';
import type { Currency } from '@streamloots/streamloots-types';

export enum PartnershipApplicationStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export interface AffiliateProgress {
  daysWithCardsRedeemedLastWeek: number;
  differentUsersRedeemingCardsCount: number;
  lootsSold: number;
  totalCardsRedeemed: number;
}

export interface AffiliateRequirements {
  minimumDaysWithRedeemedCardsLastWeek: number;
  differentUsersRedeemingCardsCount: number;
  totalLootsSold: number;
  totalCardsRedeemed: number;
}

export interface PartnershipSalesStreak {
  from: string;
  to: string;
  amount: number;
}

export interface PartnershipProgress {
  differentCustomers: number;
  differentUsersRedeemingCardsCount: number;
  lootsSold: number;
  totalCardsRedeemedLastMonth: number;
  salesStreak: PartnershipSalesStreak[];
}

export interface PartnershipRequirements {
  differentCustomers: number;
  minimumUsersRedeemingCardsLastMonth: number;
  totalLootsSold: number;
  minimumCardsRedeemedLastMonth: number;
  salesStreakMinimumWeeksAchieved: number;
  salesStreakMinimumWeeklyAmount: number;
}

// Application for partnership/affiliate
export interface PageTypeApplication {
  _id: string;
  acknowledged: boolean;
  acknowledgeAt: string;
  createdAt: string;
  modifiedAt: string;
  pageId: string;
  status: PartnershipApplicationStatus;
  userId: string;
}

export type AffiliateApplication = PageTypeApplication;

export interface PartnershipApplicationData {
  userNetworks?: string[];
  preferredContactChannel: string;
  name: string;
  surname: string;
  email: string;
  birthdate: string;
  address1: string;
  address2: string;
  averageViewers: number;
  city: string;
  country: string;
  discord?: string;
  facebook?: string;
  instagram?: string;
  mainStreamingChannel: 'mixer' | 'youtube' | 'twitch' | 'trovo' | 'kick';
  reason: string;
  region: string;
  twitter?: string;
  zipCode: string;
}

export interface PartnershipApplication extends PartnershipApplicationData, PageTypeApplication {}

export type PartnershipApplicationReviewDetailsStatistics = {
  createdAt: string;
  cardsRedeemedByMonth: Array<{
    _id: { month: number; year: number };
    count: number;
  }>;
  lootsSoldByMonth: Array<{
    _id: number;
    amountCount: number;
    lootsCount: number;
  }>;
  primaryCurrency: Currency;
  totalLootsAmount: number;
  totalLootsSold: number;
};

export interface PartnershipApplicationReviewDetails extends PartnershipApplication {
  page: {
    _id: string;
    slug: string;
  };
  pageId: string;
  pageStatistics: PartnershipApplicationReviewDetailsStatistics;
  user: BasicUserData;
}
