import { AuthProviders } from '@streamloots/streamloots-types';

export const authProviders = [
  {
    i18nKey: 'common:authentication.twitch.textButton',
    signUpI18nKey: 'common:authentication.twitch.signUp',
    icon: 'twitch',
    provider: AuthProviders.TWITCH,
  },

  {
    i18nKey: 'common:authentication.youtube.textButton',
    signUpI18nKey: 'common:authentication.youtube.signUp',
    icon: 'youtube',
    provider: AuthProviders.GOOGLE,
  },
  {
    i18nKey: 'common:authentication.kick.textButton',
    signUpI18nKey: 'common:authentication.kick.signUp',
    icon: 'kick',
    provider: AuthProviders.KICK,
  },
  {
    i18nKey: 'common:authentication.facebook.textButton',
    signUpI18nKey: 'common:authentication.facebook.signUp',
    icon: 'facebook',
    provider: AuthProviders.FACEBOOK,
  },
  {
    i18nKey: 'common:authentication.google.textButton',
    signUpI18nKey: 'common:authentication.google.signUp',
    icon: 'google',
    provider: AuthProviders.GOOGLE,
  },
  {
    i18nKey: 'common:authentication.trovo.textButton',
    signUpI18nKey: 'common:authentication.trovo.signUp',
    icon: 'trovo',
    provider: AuthProviders.TROVO,
  },
];
